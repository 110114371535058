<template>
  <v-footer
    id="home-footer"
    color="secondary"
    dark
    min-height="72"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <div class="d-flex flex-wrap justify-md-start justify-center justify-md-none">
            <template v-for="(s, i) in social">
              <v-icon v-if="s.icon" :key="s.title">
                  {{s.icon}}
              </v-icon>
              <!-- <a
                :key="s.title"
                class="white--text pa-1 pa-md-0"
                :href="s.url"
                v-text="s.title"
                target="blank"
              /> -->

              <v-responsive
                v-if="i < social.length - 1"
                :key="`divider-${s.title}`"
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>
            </template>
          </div>
        </v-col>

        <v-col
          class="text-center text-md-right"
          cols="12"
          md="6"
        >
          Copyright &copy; PT ISTPRO INTI NUSA
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
      social: [
        { title: 'Facebook', url: '#', icon:'mdi-facebook' },
        { title: 'Twitter', url: '#', icon:'mdi-twitter' },
        { title: 'Instagram', url: '#', icon:'mdi-instagram' },
        { title: 'Linkedin', url: '#', icon:'mdi-linkedin' },
      ],
    }),
  }
</script>

<style lang="sass">
  #home-footer a
    text-decoration: none
</style>
